import { RootState } from "../app/Store";
import { useState, useEffect } from "react";
import { setEmailError, setMobileNumberError } from "../app/Slices/authSlice";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import CustomButton from "../components/Common/CustomButton";
import {
  useLazyOrgApiTutorQuery,
  useUpdateB2BMutation,
  useUpdateB2CMutation,
  useLazyUserProfileQuery,
} from "../api/profileApi";
import { getItem } from "../utils/storage";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setProfileData } from "../app/Slices/profileSlice";
import CommonEdit from "../components/Tutor/EditTutor/CommonEdit";
import { GetFile, upload_file_to_s3 } from "../utils/utils";

const ProfileCard = () => {
  const dispatch = useAppDispatch();
  const { profileData } = useAppSelector(
    (state: RootState) => state.root.profile
  );
  const { isOrg, emailError, mobileNumberError } = useAppSelector(
    (state: RootState) => state.root.auth
  );
  const roles = getItem("roles");

  const [userProfile, { currentData }] = useLazyUserProfileQuery({
    refetchOnReconnect: true,
  });
  const [updateB2B] = useUpdateB2BMutation();
  const [updateB2C] = useUpdateB2CMutation();
  const [orgApiTutor, { data }] = useLazyOrgApiTutorQuery();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadedDocument, setUploadedDocument] = useState<any>(null);

  useEffect(() => {
    const getData = async () => {
      const res = await userProfile({}).unwrap();
      if (isOrg && roles.includes("TUTOR") && res) {
        orgApiTutor(res.orgName);
      }
    };
    getData();
  }, [isOrg, roles]);

  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(setProfileData({ ...profileData, [name]: value }));
    if (profileData?.email && !emailPattern.test(profileData.email)) {
      dispatch(setEmailError("Please Enter Valid Email Ex:abc@domain.com"));
    } else {
      dispatch(setEmailError(""));
    }
  };

  const validateProfileData = (profileData: any, dispatch: any) => {
    if (profileData?.email && !emailPattern.test(profileData?.email)) {
      dispatch(setEmailError("Please Enter Valid Email Ex:abc@domain.com"));
      return false;
    }
    if (
      profileData?.mobileNumber &&
      !isValidPhoneNumber(profileData.mobileNumber)
    ) {
      dispatch(setMobileNumberError("Please Enter Valid Mobile Number"));
      return false;
    }
    dispatch(setEmailError(""));
    dispatch(setMobileNumberError(""));
    return true;
  };

  const preparePayload = (
    profileData: any,
    currentData: any,
    isOrg: boolean,
    roles: string[]
  ) => {
    const payload = {
      isActive: true,
      name: profileData.name || "",
      email: profileData.email || "",
      mobileNumber: profileData.mobileNumber || "",
      profilePhoto: profileData.profilePhoto || "",
      createdDate: currentData.createdDate || "",
      uploadedDocument: {
        fileName: uploadedDocument?.fileName,
        folderPath: uploadedDocument?.folderPath,
        bucketName: uploadedDocument?.bucketName,
        region: uploadedDocument?.region,
      },
    };

    if (isOrg && roles.includes("ADMIN")) {
      return {
        adminId: currentData._id,
        payload: {
          ...payload,
          orgName: profileData.orgName || "",
        },
      };
    } else {
      return {
        tutorId: currentData._id || "",
        payload: {
          ...payload,
          isOrg: isOrg,
          orgId: currentData.orgName || "",
          updatedTime: "",
          noOfStudentsAssigned: 0,
          noOfTestsGraded: 0,
          noOfTestsPending: 0,
        },
      };
    }
  };

  const updateProfile = async (
    payload: any,
    isOrg: boolean,
    roles: string[]
  ) => {
    if (isOrg && roles.includes("ADMIN")) {
      return await updateB2B(payload).unwrap();
    } else {
      return await updateB2C(payload).unwrap();
    }
  };

  const onClickHandler = async () => {
    try {
      if (!validateProfileData(profileData, dispatch)) {
        return;
      }
      const payload = preparePayload(profileData, currentData, isOrg, roles);
      const res = await updateProfile(payload, isOrg, roles);
      if (res?.error?.data) {
        toast.error("Unable to update profile details");
      } else {
        toast.success("Profile Details Updated");
      }
    } catch (error) {
      toast.error("Error updating profile");
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let file = event.target.files;
    if (file && file.length > 0) {
      const res = await upload_file_to_s3(file[0]);
      const url = await GetFile(res?.data?.fileName);
      console.log(url,'url567')
      dispatch(setProfileData({ ...profileData, profilePhoto: url }));
      setUploadedDocument(res);
      setSelectedFile(file[0]);
    }
  };

  const handleChangeMobileNumber = (value: string) => {
    dispatch(setProfileData({ ...profileData, mobileNumber: "+" + value }));
    if (value && !isValidPhoneNumber("+" + value)) {
      dispatch(setMobileNumberError("Please enter valid mobile number"));
    } else {
      dispatch(setMobileNumberError(""));
    }
  };

  const disableButton = () => {
    const { name, email, mobileNumber } = profileData;
    if (!name || !email || !mobileNumber) {
      return true;
    } else return false;
  };

  console.log(profileData,'profileData567')

  return (
    <div className="w-1/2">
      <CommonEdit
        email={profileData?.email ?? ""}
        emailError={emailError}
        handleChangeMobileNumber={handleChangeMobileNumber}
        handleFileChange={handleFileChange}
        mobileNumber={profileData?.mobileNumber ?? ""}
        mobileNumberError={mobileNumberError}
        name={profileData?.name ?? ""}
        onChangeHandler={onChangeHandler}
        profilePhoto={profileData?.profilePhoto ?? ""}
        selectedFile={selectedFile}
        orgName={profileData?.orgName ?? ""}
        data={data}
      >
        <CustomButton
          disabled={disableButton()}
          name="Update"
          onClickHandler={onClickHandler}
          width="w-full"
        />
      </CommonEdit>
    </div>
  );
};
export default ProfileCard;
